import {mapState} from "vuex";

export default {
  methods: {

    /**
     * Get constant values array
     * 
     * @param {*} constant 
     * @param {*} excludes 
     */
    $in(constant, excludes = []) {
      let values = this.constants[constant] || [];
      
      if (excludes.length) {
        values = values.filter((item) => {
            if (!excludes.includes(item.name)) {
              return item;
            }
        })
      }

      return values;
    },

    /**
     * Get constant value, if isReverse then text
     *
     * @param constantsClass
     * @param constant
     * @param isReverse
     * @returns {*}
     */
    $const(constantsClass, constant, isReverse) {
      const constants = this.$in(constantsClass);

      let attribute = isReverse ? 'text' : 'value';

      for (let i = 0; i < constants.length; i++) {
        if (constants[i].name === constant) {
          return constants[i][attribute];
        }
      }

      return null;
    },

    /**
     * 
     * @param {*} constantsClass 
     * @param {*} value 
     * @returns 
     */
    $constName(constantsClass, value) {
      const constants = this.$in(constantsClass);
      for (let i = 0; i < constants.length; i++) {
        if (constants[i].value === value) {
          return constants[i]['name'];
        }
      }

      return null;
    },

    /**
     * Get constant text
     *
     * @param constantsClass
     * @param value
     * @returns {null}
     */
    $constText(constantsClass, value) {
      const constants = this.$in(constantsClass);

      for (let i = 0; i < constants.length; i++) {
        if (constants[i].value === value) {
          return constants[i].text;
        }
      }

      return null;
    }
  },
  computed: {
    ...mapState({
      constants: state => state.app.constants,
    })
  }
}
