<template>
  <v-app id="app">
    <v-container fluid v-if="!loaded" bg fill-height grid-list-md text-xs-center class="secondary">
      <v-row align="center" justify="center">
        <v-col cols="12" md="2" xs="12" align="center">
            <v-progress-circular
              size="150"
              :width="7"
              color="primary"
              indeterminate
            >
              <v-img v-if="!whitelabel.image" src="/img/icon.svg" max-width="80px" class="mb-2"/>
            </v-progress-circular>
        </v-col>
      </v-row>
    </v-container>

    <auth-layout v-if="layout === 'auth' && loaded"/>
    <default-layout v-if="layout === 'default' && loaded"/>
    <empty-layout v-if="layout === 'empty' && loaded"/>

    <v-snackbar
      style="z-index: 5110"
      v-model="notification.exists"
      :color="notification.color"
      :timeout="5000"
    >
      <!-- <div class="v-menu__content--active" style="display:none; z-index:1000;"></div> -->
      {{ notification.text }}
      <!-- <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="hideNotification"
        >
          {{ $lang('main.close') }}
        </v-btn>
      </template> -->
    </v-snackbar>

    <v-snackbar
      v-model="promptUpdate"
      timeout="-1"
      bottom>
      {{ $lang('auth.service_worker.new_version_found') }}
      <template v-slot:action="{ }">
        <v-btn
          color="white"
          text
          @click="updateSW"
        >
          {{ $lang('auth.service_worker.update') }}
        </v-btn>
        <v-btn
          color="white"
          text
          @click="promptUpdate = false"
        >
          {{ $lang('auth.service_worker.close') }}
        </v-btn>
      </template>
    </v-snackbar>

    <v-overlay :value="appLoading">
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
  </v-app>
</template>

<script>
  import { mapState } from "vuex";
  // import support from '@/mixins/support';
  import zendesk from '@/mixins/zendesk';

  import {
    LOAD,
    NOTIFICATION_HIDE
  } from "@/store/app/actions";

  import {
    LOGIN_PURGE
  } from "@/store/auth/actions";

  import ApiService from "@/services/api";

  const AuthLayout = () => import(/*webpackChunkName: "auth" */ "@/components/layouts/Auth");
  const DefaultLayout = () => import(/*webpackChunkName: "default-layout" *//* webpackPrefetch: true */"@/components/layouts/Default");
  const EmptyLayout = () => import(/*webpackChunkName: "empty" */ "@/components/layouts/Empty");
  const RegisterLayout = () => import(/*webpackChunkName: "register" */"@/components/layouts/Register");

  export default {
    name: "App",
    components: {
      AuthLayout,
      DefaultLayout,
      EmptyLayout,
      RegisterLayout,
    },
    mixins: [
      // support,
      zendesk,
    ],
    data() {
      return {
        promptUpdate: false,
        layout: null,
        isLoaded: false,
      };
    },
    created() {
      if (this.$workbox) {
        this.$workbox.addEventListener("waiting", () => {
          this.promptUpdate = true;
        });
      }
      ApiService.handleRequest();
      ApiService.handleResponse(this.handleResponseErrors);
    },
    beforeMount() {
      this.setLayout();
      this.load();
      this.$monitorService.identify();
    },
    methods: {
      load() {
        if (this.isLoaded) {
          return;
        }

        let config = {};

        if (this.layout === 'auth' || this.layout === 'register') {
          config.translationModules = ['auth'];
        }
        if (this.layout === 'privacy') {
          config.translationModules = ['guests'];
        }

        this.$store.dispatch(LOAD, config);

        this.isLoaded = true;
      },
      handleResponseErrors(status, message) {
        if (status === 401) {
          this.showNotification(this.$lang('main.session_expired'), 'warning');
          this.$store.dispatch(LOGIN_PURGE);
          this.$router.push({name: "login"});
        }
        if (status === 400 || status === 403) {
          this.showNotification(message ? message : this.$lang("main.crud.something_went_wrong"), "error");
        }
        if (status === 503) {
          this.$router.push({name: "maintenance"});
        }
      },
      async updateSW() {
        this.promptUpdate = false;
        await this.$workbox.messageSW({ type: "SKIP_WAITING" });
      },
      setPageTitle() {
        const suffix = 'CiaoBooking PMS';

        if (!!this.$route.meta.title) {
          const title = this.$lang(`main.${this.$route.meta.title}`);
          document.title = `${title} | ${suffix}`;
        }
      },
      setLayout() {
        if (this.$route.meta.layout) {
          this.layout = this.$route.meta.layout;
        } else {
          this.layout = this.$isAuthenticated() ? 'default' : 'auth';
        }
      },
      hideNotification() {
        this.$store.dispatch(NOTIFICATION_HIDE);
      },
    },
    watch: {
      $route() {
        if (!this.$isAuthenticated()) {
          this.$store.dispatch(LOGIN_PURGE);
        }

        this.setPageTitle();
        this.setLayout();
        this.load();
      }
    },
    computed: {
      ...mapState({
        loaded: state => state.app.loaded,
        whitelabel: state => state.settings.whitelabel,
      })
    }
  };
</script>
