import action from "@/store/core/action";

import {
  GET_PRODUCTION_REPORT,
  GET_PRODUCTION_REPORT_SUCCESS,
  GET_PRODUCTION_REPORT_FAIL,
  GET_PRODUCTION_REPORT_RESET,

  GET_AUDIT_REPORT,
  GET_AUDIT_REPORT_SUCCESS,
  GET_AUDIT_REPORT_FAIL,
  GET_AUDIT_REPORT_RESET,

  GET_MEAL_PLAN_REPORT,
  GET_MEAL_PLAN_REPORT_SUCCESS,
  GET_MEAL_PLAN_REPORT_FAIL,
  GET_MEAL_PLAN_REPORT_RESET,

  GET_INHOUSE_GUESTS_REPORT,
  GET_INHOUSE_GUESTS_REPORT_SUCCESS,
  GET_INHOUSE_GUESTS_REPORT_FAIL,
  GET_INHOUSE_GUESTS_REPORT_RESET,

  GET_FEEDS_REGISTER_REPORT,
  GET_FEEDS_REGISTER_REPORT_SUCCESS,
  GET_FEEDS_REGISTER_REPORT_FAIL,
  GET_FEEDS_REGISTER_REPORT_RESET,

  GET_HOUSEKEEPING_DAILY_REPORT,
  GET_HOUSEKEEPING_DAILY_REPORT_SUCCESS,
  GET_HOUSEKEEPING_DAILY_REPORT_FAIL,
  GET_HOUSEKEEPING_DAILY_REPORT_RESET,

  GET_HOUSEKEEPING_REPORT,
  GET_HOUSEKEEPING_REPORT_SUCCESS,
  GET_HOUSEKEEPING_REPORT_FAIL,
  GET_HOUSEKEEPING_REPORT_RESET,

  GET_WORK_ORDERS_REPORT,
  GET_WORK_ORDERS_REPORT_SUCCESS,
  GET_WORK_ORDERS_REPORT_FAIL,
  GET_WORK_ORDERS_REPORT_RESET,

  GET_PAYMENTS_REPORT,
  GET_PAYMENTS_REPORT_SUCCESS,
  GET_PAYMENTS_REPORT_FAIL,
  GET_PAYMENTS_REPORT_RESET,

  GET_REVENUE_SHARE_REPORT,
  GET_REVENUE_SHARE_REPORT_SUCCESS,
  GET_REVENUE_SHARE_REPORT_FAIL,
  GET_REVENUE_SHARE_REPORT_RESET,

  GET_REVENUE_REPORT,
  GET_REVENUE_REPORT_SUCCESS,
  GET_REVENUE_REPORT_FAIL,
  GET_REVENUE_REPORT_RESET,

  GET_OCCUPATION_REPORT,
  GET_OCCUPATION_REPORT_SUCCESS,
  GET_OCCUPATION_REPORT_FAIL,
  GET_OCCUPATION_REPORT_RESET,

  GET_CUSTOMER_SUPPORT_REPORT,
  GET_CUSTOMER_SUPPORT_REPORT_SUCCESS,
  GET_CUSTOMER_SUPPORT_REPORT_FAIL,
  GET_CUSTOMER_SUPPORT_REPORT_RESET,

  GET_AGENCY_MANAGER_REPORT,
  GET_AGENCY_MANAGER_REPORT_SUCCESS,
  GET_AGENCY_MANAGER_REPORT_FAIL,
  GET_AGENCY_MANAGER_REPORT_RESET,

  GET_CITY_TAX_REPORT,
  GET_CITY_TAX_REPORT_SUCCESS,
  GET_CITY_TAX_REPORT_FAIL,
  GET_CITY_TAX_REPORT_RESET,
} from "./actions";

const actionsProcessed = action.processMultiple([
  {
    name: 'getProductionReportRequest',
    action: GET_PRODUCTION_REPORT,
    actionReset: GET_PRODUCTION_REPORT_RESET,
    endPoint: 'reports/production',
    mutations: {
      fail: GET_PRODUCTION_REPORT_FAIL,
      success: GET_PRODUCTION_REPORT_SUCCESS,
    },
  },
  {
    name: 'getAuditReportRequest',
    action: GET_AUDIT_REPORT,
    actionReset: GET_AUDIT_REPORT_RESET,
    endPoint: 'reports/audit',
    mutations: {
      fail: GET_AUDIT_REPORT_FAIL,
      success: GET_AUDIT_REPORT_SUCCESS,
    },
  },
  {
    name: 'getMealPlanReportRequest',
    action: GET_MEAL_PLAN_REPORT,
    actionReset: GET_MEAL_PLAN_REPORT_RESET,
    endPoint: 'reports/meal-plan',
    mutations: {
      fail: GET_MEAL_PLAN_REPORT_FAIL,
      success: GET_MEAL_PLAN_REPORT_SUCCESS,
    },
  },
  {
    name: 'getInhouseGuestsReportRequest',
    action: GET_INHOUSE_GUESTS_REPORT,
    actionReset: GET_INHOUSE_GUESTS_REPORT_RESET,
    endPoint: 'reports/inhouse-guests',
    mutations: {
      fail: GET_INHOUSE_GUESTS_REPORT_FAIL,
      success: GET_INHOUSE_GUESTS_REPORT_SUCCESS,
    },
  },
  {
    name: 'getFeedsRegisterReportRequest',
    action: GET_FEEDS_REGISTER_REPORT,
    actionReset: GET_FEEDS_REGISTER_REPORT_RESET,
    endPoint: 'reports/feeds-register',
    mutations: {
      fail: GET_FEEDS_REGISTER_REPORT_FAIL,
      success: GET_FEEDS_REGISTER_REPORT_SUCCESS,
    }
  },
  {
    name: 'getHousekeepingDailyReportRequest',
    action: GET_HOUSEKEEPING_DAILY_REPORT,
    actionReset: GET_HOUSEKEEPING_DAILY_REPORT_RESET,
    endPoint: 'reports/housekeeping-daily',
    mutations: {
      fail: GET_HOUSEKEEPING_DAILY_REPORT_FAIL,
      success: GET_HOUSEKEEPING_DAILY_REPORT_SUCCESS,
    },
  },
  {
    name: 'getHousekeepingReportRequest',
    action: GET_HOUSEKEEPING_REPORT,
    actionReset: GET_HOUSEKEEPING_REPORT_RESET,
    endPoint: 'reports/housekeeping',
    mutations: {
      fail: GET_HOUSEKEEPING_REPORT_FAIL,
      success: GET_HOUSEKEEPING_REPORT_SUCCESS,
    },
  },
  {
    name: 'getWorkOrdersReportRequest',
    action: GET_WORK_ORDERS_REPORT,
    actionReset: GET_WORK_ORDERS_REPORT_RESET,
    endPoint: 'reports/work-orders',
    mutations: {
      fail: GET_WORK_ORDERS_REPORT_FAIL,
      success: GET_WORK_ORDERS_REPORT_SUCCESS,
    }
  },
  {
    name: 'getPaymentsReportRequest',
    action: GET_PAYMENTS_REPORT,
    actionReset: GET_PAYMENTS_REPORT_RESET,
    endPoint: 'reports/payments',
    mutations: {
      fail: GET_PAYMENTS_REPORT_FAIL,
      success: GET_PAYMENTS_REPORT_SUCCESS,
    },
  },
  {
    name: 'getRevenueShareReportRequest',
    action: GET_REVENUE_SHARE_REPORT,
    actionReset: GET_REVENUE_SHARE_REPORT_RESET,
    endPoint: 'reports/revenue-share',
    mutations: {
      fail: GET_REVENUE_SHARE_REPORT_FAIL,
      success: GET_REVENUE_SHARE_REPORT_SUCCESS,
    },
  },
  {
    name: 'getRevenueReportRequest',
    action: GET_REVENUE_REPORT,
    actionReset: GET_REVENUE_REPORT_RESET,
    endPoint: 'reports/revenue',
    mutations: {
      fail: GET_REVENUE_REPORT_FAIL,
      success: GET_REVENUE_REPORT_SUCCESS,
    },
  },
  {
    name: 'getOccupationReportRequest',
    action: GET_OCCUPATION_REPORT,
    actionReset: GET_OCCUPATION_REPORT_RESET,
    endPoint: 'reports/occupation',
    mutations: {
      fail: GET_OCCUPATION_REPORT_FAIL,
      success: GET_OCCUPATION_REPORT_SUCCESS,
    },
  },
  {
    name: 'getCustomerSupportRequest',
    action: GET_CUSTOMER_SUPPORT_REPORT,
    actionReset: GET_CUSTOMER_SUPPORT_REPORT_RESET,
    endPoint: 'reports/customer-support',
    mutations: {
      fail: GET_CUSTOMER_SUPPORT_REPORT_FAIL,
      success: GET_CUSTOMER_SUPPORT_REPORT_SUCCESS,
    },
  },
  {
    name: 'getAgencyManagerReportRequest',
    action: GET_AGENCY_MANAGER_REPORT,
    actionReset: GET_AGENCY_MANAGER_REPORT_RESET,
    endPoint: 'reports/agency-manager',
    mutations: {
      fail: GET_AGENCY_MANAGER_REPORT_FAIL,
      success: GET_AGENCY_MANAGER_REPORT_SUCCESS,
    },
  },
  {
    name: 'getCityTaxReportRequest',
    action: GET_CITY_TAX_REPORT,
    actionReset: GET_CITY_TAX_REPORT_RESET,
    endPoint: 'reports/city-tax',
    mutations: {
      fail: GET_CITY_TAX_REPORT_FAIL,
      success: GET_CITY_TAX_REPORT_SUCCESS,
    },
  },
]);

const getters = {};

const state = {
  ...actionsProcessed.state,
};

const actions = {
  ...actionsProcessed.actions,
};

const mutations = {
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
