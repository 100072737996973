<template>
  <v-switch
    v-model="switchValue"
    :loading="loading"
    :label="label"
    :hint="hint"
    :hide-details="hideDetails"
    :error="error"
    :error-messages="errorMessages"
    :true-value="1"
    :false-value="0"
    :prepend-icon="prependIcon"
    :append-icon="appendIcon"
    :disabled="disabled"
    :readonly="readonly"
    :persistent-hint="persistentHint"
    :dense="dense"
    :color="color"
    @change="$emit('change', switchValue)"
  />
</template>

<script>
  export default {
    name: "VBoolean",
    props: {
      value: {
        default: null,
      },
      label: {
        type: String,
        default: null,
      },
      hint: {
        type: String,
        default: null,
      },
      hideDetails: {
        type: Boolean,
        default: false,
      },
      error: {
        type: Boolean,
        required: false,
      },
      errorMessages: {
        type: Array,
        required: false,
        default: () => {
          return [];
        }
      },
      loading: {
        type: Boolean,
        default: false,
      },
      prependIcon: {
        type: String,
        default: null,
      },
      appendIcon: {
        type: String,
        default: null,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      readonly: {
        type: Boolean,
        default: false,
      },
      persistentHint: {
        type: Boolean,
        default: false,
      },
      dense: {
        type: Boolean,
        default: false,
      },
      color: {
        type: String,
        default: null,
      }
    },
    data() {
      return {
        switchValue: null
      }
    },
    beforeMount() {
      this.switchValue = this.value;
    },
    watch: {
      value() {
        this.switchValue = this.value;
      },
      switchValue(newVal) {
        this.$emit('input', newVal);
      }
    },
  }
</script>
