import Vue from 'vue';
import ApiService from "@/services/api";
import ExtraService from "@/services/extra";
import WorkerService from "@/services/worker";
import MixinsService from "@/services/mixins";
import ConfigService from "@/services/config";
import LibrariesService from "@/services/libs";
import FiltersService from "@/services/filters";
import ComponentsService from "@/services/components";
import MonitorService from "@/services/monitor";

const MainService = {
  /**
   *
   */
  init() {
    ApiService.init();
    ExtraService.init();
    WorkerService.init();
    MixinsService.init();
    ConfigService.init();
    FiltersService.init();
    LibrariesService.init();
    ComponentsService.init();
    Vue.prototype.$monitorService = MonitorService;
  }
};

export default MainService;
