export default [
  {
    name: "citytax",
    path: "/statistics/city-tax",
    component: () => import(/*webpackChunkName: "statistics" */ "@/pages/statistics/Citytax"),
    meta: {
      title: 'titles.city_tax_report'
    }
  },
  {
    name: "revenues",
    path: "/statistics/revenues",
    component: () => import(/*webpackChunkName: "statistics" */ "@/pages/statistics/Revenues"),
    meta: {
      title: 'titles.revenues_report'
    }
  },
  {
    name: "revenue-shares",
    path: "/statistics/revenue-shares",
    component: () => import(/*webpackChunkName: "statistics" */ "@/pages/statistics/RevenueShares"),
    meta: {
      title: 'titles.revenue_shares_report'
    }
  },
  {
    name: "reservations-report",
    path: "/statistics/reservations-report",
    component: () => import(/*webpackChunkName: "statistics" */ "@/pages/statistics/Reservations"),
    meta: {
      title: 'titles.reservations_report'
    }
  },
  {
    name: "meal-plan-report",
    path: "/reports/meal-plan-report",
    component: () => import(/*webpackChunkName: "statistics" */ "@/pages/reports/Mealplan"),
    meta: {
      title: 'titles.meal_plan_report'
    }
  },
  {
    name: "inhouse-guests-report",
    path: "/reports/inhouse-guests-report",
    component: () => import(/*webpackChunkName: "statistics" */ "@/pages/reports/InhouseGuests"),
    meta: {
      title: 'titles.inhouse_guests_report'
    }
  },
  {
    name: "production-report",
    path: "/statistics/production-report",
    component: () => import(/*webpackChunkName: "statistics" */ "@/pages/statistics/Production"),
    meta: {
      title: 'titles.production_report'
    }
  },
  {
    name: 'reservation-extras-report',
    path: '/statistics/reservation-extras',
    component: () => import(/*webpackChunkName: "reservations" */ "@/pages/reservation-extras/Report"),
    meta: {
      title: 'titles.reservation_extras',
    }
  },
  {
    name: "audit-report",
    path: "/statistics/audit-report",
    component: () => import(/*webpackChunkName: "statistics" */ "@/pages/statistics/Audit"),
    meta: {
      title: 'titles.audit_report'
    }
  },
  {
    name: "feeds-register-report",
    path: "/reports/feeds-register",
    component: () => import(/*webpackChunkName: "statistics" */ "@/pages/reports/FeedsRegister"),
    meta: {
      title: 'titles.feeds_register'
    }
  },
  {
    name: "work-orders-report",
    path: "/reports/work-orders",
    component: () => import(/*webpackChunkName: "statistics" */ "@/pages/reports/WorkOrders"),
    meta: {
      title: 'titles.work_orders'
    }
  },
  {
    name: "occupancy-report",
    path: "/reports/occupancy",
    component: () => import(/*webpackChunkName: "statistics" */ "@/pages/reports/Occupancy"),
    meta: {
      title: 'titles.occupancy_report'
    }
  },
  {
    name: "agency-manager",
    path: "/statistics/agency-manager",
    component: () => import(/*webpackChunkName: "statistics" */ "@/pages/statistics/AgencyManager"),
    meta: {
      title: 'titles.agency_manager_report'
    }
  },
  {
    name: "dac7",
    path: "/reports/dac7",
    component: () => import(/*webpackChunkName: "statistics" */ "@/pages/reports/Dac7"),
    meta: {
      title: 'titles.dac7'
    }
  },
];
