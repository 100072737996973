export const GET_PRODUCTION_REPORT = "[REPORTS] Production Report Get";
export const GET_PRODUCTION_REPORT_SUCCESS = "[REPORTS] Production Report Get Success";
export const GET_PRODUCTION_REPORT_FAIL = "[REPORTS] Production Report Get Fail";
export const GET_PRODUCTION_REPORT_RESET = "[REPORTS] Production Report Get Reset";

export const GET_AUDIT_REPORT = "[REPORTS] Audit Report Get";
export const GET_AUDIT_REPORT_SUCCESS = "[REPORTS] Audit Report Get Success";
export const GET_AUDIT_REPORT_FAIL = "[REPORTS] Audit Report Get Fail";
export const GET_AUDIT_REPORT_RESET = "[REPORTS] Audit Report Get Reset";

export const GET_MEAL_PLAN_REPORT = "[REPORTS] MealPlan Report Get";
export const GET_MEAL_PLAN_REPORT_SUCCESS = "[REPORTS] MealPlan Report Get Success";
export const GET_MEAL_PLAN_REPORT_FAIL = "[REPORTS] MealPlan Report Get Fail";
export const GET_MEAL_PLAN_REPORT_RESET = "[REPORTS] MealPlan Report Get Reset";

export const GET_INHOUSE_GUESTS_REPORT = "[REPORTS] InhouseGuests Report Get";
export const GET_INHOUSE_GUESTS_REPORT_SUCCESS = "[REPORTS] InhouseGuests Report Get Success";
export const GET_INHOUSE_GUESTS_REPORT_FAIL = "[REPORTS] InhouseGuests Report Get Fail";
export const GET_INHOUSE_GUESTS_REPORT_RESET = "[REPORTS] InhouseGuests Report Get Reset";

export const GET_FEEDS_REGISTER_REPORT = "[REPORTS] FeedsRegister Report Get";
export const GET_FEEDS_REGISTER_REPORT_SUCCESS = "[REPORTS] FeedsRegister Report Get Success";
export const GET_FEEDS_REGISTER_REPORT_FAIL = "[REPORTS] FeedsRegister Report Get Fail";
export const GET_FEEDS_REGISTER_REPORT_RESET = "[REPORTS] FeedsRegister Report Get Reset";

export const GET_HOUSEKEEPING_DAILY_REPORT = "[REPORTS] Housekeeping Daily Report Get";
export const GET_HOUSEKEEPING_DAILY_REPORT_SUCCESS = "[REPORTS] Housekeeping Daily Report Get Success";
export const GET_HOUSEKEEPING_DAILY_REPORT_FAIL = "[REPORTS] Housekeeping Daily Report Get Fail";
export const GET_HOUSEKEEPING_DAILY_REPORT_RESET = "[REPORTS] Housekeeping Daily Report Get Fail";

export const GET_HOUSEKEEPING_REPORT = "[REPORTS] Housekeeping Report Get";
export const GET_HOUSEKEEPING_REPORT_SUCCESS = "[REPORTS] Housekeeping Report Get Success";
export const GET_HOUSEKEEPING_REPORT_FAIL = "[REPORTS] Housekeeping Report Get Fail";
export const GET_HOUSEKEEPING_REPORT_RESET = "[REPORTS] Housekeeping Report Get Fail";

export const GET_WORK_ORDERS_REPORT = "[REPORTS] WorkOrders Report Get";
export const GET_WORK_ORDERS_REPORT_SUCCESS = "[REPORTS] WorkOrders Report Get Success";
export const GET_WORK_ORDERS_REPORT_FAIL = "[REPORTS] WorkOrders Report Get Fail";
export const GET_WORK_ORDERS_REPORT_RESET = "[REPORTS] WorkOrders Report Get Reset";

export const GET_PAYMENTS_REPORT = "[PAYMENTS] Report Get";
export const GET_PAYMENTS_REPORT_SUCCESS = "[PAYMENTS] Report Get Success";
export const GET_PAYMENTS_REPORT_FAIL = "[PAYMENTS] Report Get Fail";
export const GET_PAYMENTS_REPORT_RESET = "[PAYMENTS] Report Get Reset";

export const GET_REVENUE_SHARE_REPORT = "[REPORTS] Revenue Share Report Get";
export const GET_REVENUE_SHARE_REPORT_SUCCESS = "[REPORTS] Revenue Share Report Get Success";
export const GET_REVENUE_SHARE_REPORT_FAIL = "[REPORTS] Revenue Share Report Get Fail";
export const GET_REVENUE_SHARE_REPORT_RESET = "[REPORTS] Revenue Share Report Get Reset";

export const GET_REVENUE_REPORT = "[REPORTS] Revenue Report Get";
export const GET_REVENUE_REPORT_SUCCESS = "[REPORTS] Revenue Report Get Success";
export const GET_REVENUE_REPORT_FAIL = "[REPORTS] Revenue Report Get Fail";
export const GET_REVENUE_REPORT_RESET = "[REPORTS] Revenue Report Get Reset";

export const GET_OCCUPATION_REPORT = "[REPORTS] Occupation Report Get";
export const GET_OCCUPATION_REPORT_SUCCESS = "[REPORTS] Occupation Report Get Success";
export const GET_OCCUPATION_REPORT_FAIL = "[REPORTS] Occupation Report Get Fail";
export const GET_OCCUPATION_REPORT_RESET = "[REPORTS] Occupation Report Get Reset";

export const GET_CUSTOMER_SUPPORT_REPORT = "[REPORTS] Customer Support Report Get";
export const GET_CUSTOMER_SUPPORT_REPORT_SUCCESS = "[REPORTS] Customer Support Report Get Success";
export const GET_CUSTOMER_SUPPORT_REPORT_FAIL = "[REPORTS] Customer Support Report Get Fail";
export const GET_CUSTOMER_SUPPORT_REPORT_RESET = "[REPORTS] Customer Support Report Get Reset";

export const GET_AGENCY_MANAGER_REPORT = "[REPORTS] Agency Manager Report Get";
export const GET_AGENCY_MANAGER_REPORT_SUCCESS = "[REPORTS] Agency Manager Report Get Success";
export const GET_AGENCY_MANAGER_REPORT_FAIL = "[REPORTS] Agency Manager Report Get Fail";
export const GET_AGENCY_MANAGER_REPORT_RESET = "[REPORTS] Agency Manager Report Get Reset";

export const GET_CITY_TAX_REPORT = "[REPORTS] CityTax Report Get";
export const GET_CITY_TAX_REPORT_SUCCESS = "[REPORTS] CityTax Report Get Success";
export const GET_CITY_TAX_REPORT_FAIL = "[REPORTS] CityTax Report Get Fail";
export const GET_CITY_TAX_REPORT_RESET = "[REPORTS] CityTax Report Get Reset";