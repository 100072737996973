export default {
  mounted() {
    const zendeskScript = document.createElement('script');
    zendeskScript.id = 'ze-snippet';
    zendeskScript.src = 'https://static.zdassets.com/ekr/snippet.js?key=f2ed3194-d621-43be-abb7-7840b9f63cd5';
    zendeskScript.async = true;
    document.body.appendChild(zendeskScript);

    zendeskScript.onload = () => {
      window.zE(() => {
        this.authenticateUser();
      });
    };
  },
  methods: {
    authenticateUser() {
      if (!this.userData.zendesk) {
        console.warn("Unable to authenticate chat");
        return;
      }

      zE("messenger", "loginUser", (callback) => {
        console.log("Authenticating chat");
        callback(this.userData.zendesk);
      });
    },
    identifyHubspot() {
      var _hsq = (window._hsq = window._hsq || []);
      _hsq.push([
        'identify',
        {
          email: this.userData.email,
        },
      ]);
    }
  },
  watch: {
    'userData.zendesk': {
      handler() {
        this.authenticateUser();
        this.identifyHubspot();
      }
    }
  }
};
